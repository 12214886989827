<template>
  <div :class="show ? 'h5main main' : 'main'">
    <mtop />
    <div class="swarp">
      <img src="../assets/images/iphone.png" class="phone" />
      <div class="box">
        <div class="stopd">
          <img src="../assets/images/ailogo.png" class="logo" />
          <div class="aitxt">
            <img src="../assets/images/xingke.png" class="xingke" />
            <div>智能聊天写作机器人</div>
          </div>
        </div>
        <div class="title">先进的人工智能聊天写作机器人</div>
        <div class="tips">下载星科AI，创作更智能</div>
        <div class="download" @click="todown">
          <img src="../assets/images/Android.png" class="Android" />
          <div class="txt">Android版下载</div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="contbox">
        <div class="txtbox">
          <div class="title">百晓通</div>
          <div class="next">
            <div class="t1">各行业问题无所不知；</div>
            <div class="t2">
              语言翻译、难题破解、宇宙奥义、历史谜题、学习工作
              美食烹饪、健康养生
            </div>
          </div>
        </div>
        <div class="imgbox">
          <img
            src="../assets/images/t1.png"
            alt="NFT"
            title="NFT"
            class="img"
          />
        </div>
      </div>
    </div>
    <div class="content wbg">
      <div class="contbox">
        <div class="imgbox">
          <img
            src="../assets/images/t2.png"
            alt="NFT"
            title="NFT"
            class="img"
          />
        </div>
        <div class="txtbox">
          <div class="title">创作者</div>
          <div class="next">
            <div class="t1">您的AI创作师；</div>
            <div class="t2">
              小说、文章、诗歌情怀、论文、学习计划、概括总结、
              周报、日程计划、发言稿、新媒体写作、短视频脚本、知乎问答、学科出题、广告标语、合同模版、好评文案、旅行攻略、节日祝福、心情日记、朋友圈文案
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="contbox">
        <div class="txtbox">
          <div class="title">私人助理</div>
          <div class="next">
            <div class="t1">您的私人助理；</div>
            <div class="t2">
              学习顾问、医疗顾问、法律顾问、星象占卜、旅游出行、语言翻译、美食探店、私人厨房、营销策划、AI编程、面试官、英语发音帮手、讲故事、歌曲创作助手、辩手、编剧、说唱歌手、职业顾问、心理医生、会计师、投资顾问、室内装修设计师、美食评论家、个人造型师
            </div>
          </div>
        </div>
        <div class="imgbox">
          <img
            src="../assets/images/t3.png"
            alt="NFT"
            title="NFT"
            class="img"
          />
        </div>
      </div>
    </div>
    <mbottom />
  </div>
</template>

<script>
import mtop from "../components/top.vue";
import mbottom from "../components/bottom.vue";

export default {
  data() {
    return {
      show: false,
    };
  },
  components: {
    mtop,
    mbottom,
  },

  mounted() {
    if (this._isMobile()) {
      this.show = true;
    } else {
      this.show = this.$store.getters.phone;
    }
    //   let divgao=document.querySelector(".right_box1").offsetTop;
    //   this.heightList.push(divgao);
    //   window.addEventListener('scroll',this.handlerScroll,true)
  },
  watch: {
    "$store.getters.phone": function (val) {
      this.show = val;
    },
    deep: true,
    immediate: true,
  },
  created() {},
  methods: {
    todown() {
      // this.$toast("敬请期待");
      window.open(
        "https://xinrunkeji.oss-cn-beijing.aliyuncs.com/xingkeai/xkai_v1.0_2023061414.apk"
      );
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    topage(e) {
      this.$router.replace({ path: e });
      this.$store.commit("sid", "");
    },
    // tobuy() {
    //    location.href = "https://opensea.io/collection/cupid-love-metaverse";
    // },
  },
};
</script>
<style lang="scss" scoped>
$t-mf: "Arial";
.main {
  background: #ffffff;
  // background: pink;
  min-height: 100vh;
  max-width: 100%;
  min-width: 1200px;
  width: 100%;
  .swarp {
    background: url("../assets/images/aibg.png") no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 1010px;
    display: flex;
    align-items: center;
    justify-content: center;
    .phone {
      width: 522px;
      height: 763px;
      margin-right: 103px;
    }
    .box {
      .stopd {
        display: flex;
        align-items: center;
        .logo {
          width: 123px;
          height: 123px;
        }
        .aitxt {
          margin-left: 24px;
          .xingke {
            width: 188px;
            height: 70px;
          }
          font-size: 24px;
          font-weight: 400;
          color: #646566;
          line-height: 36px;
        }
      }
      .title {
        font-size: 32px;
        font-weight: 500;
        color: #1d2129;
        line-height: 50px;
        margin-top: 80px;
      }
      .tips {
        font-size: 24px;
        font-weight: 400;
        color: #1d2129;
        line-height: 36px;
        margin-top: 8px;
      }
      .download {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 369px;
        height: 91px;
        background: #ffffff;
        border-radius: 4px;
        margin-top: 40px;
        cursor: pointer;
        .Android {
          width: 48px;
          height: 48px;
          margin-right: 20px;
        }
        .txt {
          font-size: 32px;
          font-weight: 400;
          color: #1d2129;
          line-height: 45px;
        }
      }
    }
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f5f6fa;
    .contbox {
      width: 1200px;
      height: 887px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .txtbox {
      .title {
        font-size: 24px;
        font-weight: 500;
        color: #1d2129;
        line-height: 33px;
        position: relative;
        padding-left: 12px;
      }
      .title::before {
        content: "";
        width: 4px;
        height: 21px;
        background: #2b6bff;
        position: absolute;
        left: 0;
        top: 6px;
      }
      .next {
        width: 386px;
        margin-top: 40px;
        .t1 {
          font-size: 16px;
          font-weight: 400;
          color: #1d2129;
          margin-bottom: 16px;
        }
        .t2 {
          font-size: 16px;
          font-weight: 400;
          color: #1d2129;
        }
      }
    }
    .imgbox {
      width: 718px;
      height: 718px;
      .img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .wbg {
    background: #ffffff;
  }
}
.h5main {
  background: #f7f7f7;
  min-height: 100vh;
  max-width: 750px;
  min-width: 640px;
  width: 100%;
  .swarp {
    flex-direction: column;
    background: url("../assets/images/aipbg.png") no-repeat;
    background-size: 100% 100%;
    height: auto;
    padding: 100px 0;
    .phone {
      width: 600px;
      height: auto;
      margin-right: 0;
      margin-bottom: 50px;
    }
    .box {
      .stopd {
        .logo {
          width: 88px;
          height: 88px;
        }
        .aitxt {
          margin-left: 9px;
          font-size: 24px;
          .xingke {
            width: 73px;
            height: auto;
          }
        }
      }
      .title {
        margin-top: 20px;
        font-size: 26px;
      }
    }
  }
  .content {
    .contbox {
      width: calc(100% - 52px);
      height: auto;
      padding: 26px;
      flex-direction: column;
      .imgbox {
        width: 80%;
        height: auto;
        margin: 100px 0;
      }
      .txtbox {
        width: 100%;
        .title {
          font-size: 32px;
          margin-top: 32px;
        }
        .next {
          width: 100%;
          .t1 {
            font-size: 26px;
          }
          .t2 {
            font-size: 26px;
          }
        }
      }
    }
  }
  .wbg {
    .contbox {
      flex-direction: column-reverse;
    }
  }
}
</style>